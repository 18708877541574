export default {
    methods: {
        /**
         * 转义符换成普通字符
         * @param {*} str
         * @returns
         */
        escape2Html (str) {
            if (!str) return str
            var arrEntities = {
                'lt': '<',
                'gt': '>',
                'nbsp': ' ',
                'amp': '&',
                'quot': '"'
            }
            return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
                return arrEntities[t]
            })
        },
        /**
         * 普通字符转换成转义符
         * @param {*} sHtml
         * @returns
         */
        html2Escape (sHtml) {
            if (!sHtml) return sHtml
            return sHtml.replace(/[<>&"]/g, function (c) {
                return {
                    '<': '&lt;',
                    '>': '&gt;',
                    '&': '&amp;',
                    '"': '&quot;'
                }[c]
            })
        },
        /**
         * 解析事件里的动态函数名，这种没有()的函数名，在uniapp不被执行
         * 比如：<view bindtap="{{openId==undefined?'denglu':'hy_to'}}">立即</view>
         * @param {*} exp
         */
        parseEventDynamicCode (exp) {
            if (typeof (eval("this." + exp)) === 'function') {
                eval("this." + exp + '()')
            }
        },
        /**
         * 用于处理对props进行赋值的情况
         * //简单处理一下就行了
         *
         * @param {*} obj
         * @returns
         */
        deepClone (obj) {
            return JSON.parse(JSON.stringify(obj))
        },
        formatNumber(val){
            let valAry = val.split('.');
            let valAry0 = valAry[0].split('').reverse();
            let newAry = []
            for(var i=0; i<valAry0.length; i++){
                newAry.push(valAry0[i])
               if(i>0 && (i+1) % 3 == 0 && i<valAry0.length-1){
                newAry.push(',')
               }
            }
            newAry = newAry.reverse();
            val = newAry.join('');
            if(valAry[1]){
                val = val+'.'+valAry[1]
            }
            
            return val;
        },
    }
}
