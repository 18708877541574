import axios from 'axios';
import siteinfo from './siteinfo.js';
import qs from 'qs'
import router from '@/router'
import {Message} from 'element-ui';
let baseURL = siteinfo.siteroot + 'index.php?s=/';

if(process.env.NODE_ENV=='development'){
    baseURL = '/api/index.php?s=/'
}else{
    baseURL = siteinfo.siteroot + 'index.php?s=/';
}

const $http = axios.create({
    baseURL,
    withCredentials: true,
    header: {
        'content-type': 'application/x-www-form-urlencoded',
        'sykj-token-id': 'sykj',
    }
})
// create 是axios自带的方法
//$http拥有axios的get和post方法

// 添加请求拦截器，在请求头中加token
$http.interceptors.request.use(
    config => {
        let {data,params} = config || {};
        let token = localStorage.getItem('token') || '';
        if (config.method === 'post') {
            data.token = token;
            data.refer_source = 'metaverse';
            data.wxapp_id = siteinfo.uniacid;
            data = qs.stringify(data);
            config.data = data;
        }else{
            params.token = token;
            params.refer_source = 'metaverse';
            params.wxapp_id = siteinfo.uniacid;
            config.params = params;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });

//在 response 拦截器实现
$http.interceptors.response.use(
    res => {
        const data = res.data;
        // 如果是未登录
        if (res.status !== 200 || typeof data !== 'object') {
            showError('活动太火爆了，请您耐心等待~');
        } else {
            const {msg} = data
            if(typeof data.code === 'undefined' && typeof data.result !== 'undefined'){
                data.code = Number(data.result)
            }else if(typeof data.code === 'undefined' && typeof data.result === 'undefined'){
                data.code = 1;
            }
           
            if (data.code === -1) {
                router.push({ path: '/login' });
            } else if (data.code === 0) {
                if(msg){
                    showError(msg);
                }
               
            }
        }
      
        return data;
    }, error => {
        return Promise.reject(error);
    });


export const get = (url, params) => {
    params = params || {};
    return new Promise((resolve, reject) => {
        // axiso 自带 get 和 post 方法
        $http.get(url, {
            params,
        }).then(res => {
            if (res.code === 1) {
                resolve(res);
            }else if(res.code !== -1){
                reject(res)
            }
        }).catch(error => {
            console.log(error);
        })
    })
}

export const post = (url, params) => {
    params = params || {};
    return new Promise((resolve, reject) => {
        $http.post(url, params).then(res => {
            if (res.code === 1) {
                resolve(res);
            }else if(res.code !== -1){
                reject(res)
            }
        }).catch(error => {
            console.log(error);
        })
    })
}

function showError(msg) {
    Message({
        message: msg,
        type: "error",
    })
}
