<template>
  <div class="page-left-menu" :class="{ close: isCollapse }">
    <div class="collapse-btn" @click="toggleCollapse()">
      <i v-if="isCollapse" class="iconfont icon-jiantou-r"></i>
      <i v-else class="iconfont icon-jiantou-l"></i>
    </div>
    <el-menu
      router
      unique-opened
      :collapse="isCollapse"
      :collapse-transition="false"
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#232630"
      text-color="#BFBFBF"
      active-text-color="#43AEFE"
    >
      <template v-for="(menu,index) in menuList">
        <el-submenu
          v-if="menu.children && menu.children.length"
          :index="menu.path"
          :key="index"
          v-show="menu.meta.visiable"
        >
          <template slot="title">
            <i class="iconfont default" :class="menu.meta.icon"></i>
            <i
              class="iconfont active"
              :class="[
                menu.meta.activeIcon,
                { user: menu.path == '/user' },
                { set: menu.path == '/set' },
                { material: menu.path == '/material' },
              ]"
            ></i>
            <span>{{ menu.name }}</span>
          </template>
          <el-menu-item
            v-for="(subMenu,subIndex) in menu.children"
            :key="subIndex"
            :index="subMenu.path"
          >
            <template slot="title">
              <!-- <i class="iconfont" :class="subMenu.meta.icon"></i> -->
              <span> {{ subMenu.name }}</span>
            </template>
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-else
          :index="menu.path"
          :key="index"
          v-show="menu.meta.visiable"
        >
          <template slot="title">
            <i class="iconfont" :class="menu.meta.icon"></i>
            <span slot="title">{{ menu.name }}</span>
          </template>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: "leftMenu",
  model: {
    prop: "isCollapse",
    event: "toggleCollapse",
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuList: [],
    };
  },
  mounted() {
    this.menuList = this.$router.options.routes;
  },
  methods: {
    toggleCollapse() {
      let status = !this.isCollapse;
      this.$emit("toggleCollapse", status);
    },
    handleSelect(key, keyPath) {
      key = key.replace(keyPath[0] + "_", "");
      let query = {};
      if (keyPath[0] == "type" && this.curType != key) {
        query = { type: key };
        this.$router.push({ path: "/market", query });
      }
      if (keyPath[0] == "project" && this.curProjectId != key) {
        query = { type: this.curType, project_id: key };
        this.$router.push({ path: "/market", query });
      }
      if (keyPath[0] == "goods") {
        query = {
          type: this.curType,
          project_id: this.curProjectId,
          goods_id: key,
        };
        this.$router.push({ path: "/market", query });
      }
      if (keyPath[0] == "sku") {
        let querySkuIds = this.$route.query.sku_ids || "";
        querySkuIds = querySkuIds ? querySkuIds.split(",") : [];
        key = key.toString();
        let index = querySkuIds.indexOf(key);

        if (index > -1) {
          querySkuIds.splice(index, 1);
        } else {
          querySkuIds.push(key);
        }
        querySkuIds = querySkuIds.join(",");
        query = {
          type: this.curType,
          project_id: this.curProjectId,
          goods_id: this.curGoodsId,
          sku_ids: querySkuIds,
        };
        this.$router.push({ path: "/market", query });
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang="scss">
.page-left-menu {
  position: fixed;
  width: 220px;
  height: 100%;
  left: 0;
  top: 75px;
  bottom: 0;
  box-sizing: border-box;
  border-right: 1px solid $border-color-dark;
  transition: all 0.2s ease-out;
  background: $bg-color;
  z-index: 1;
  padding: 0 10px;
  .collapse-btn {
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
    text-align: right;
    border-bottom: 1px solid $border-color-menu;
    color: $text-color-grey;
  }
  &.close {
    width: 65px;
  }
  .el-menu {
    border-right: none;
    max-height: calc(100vh - 116px);
    overflow: auto;
    padding-bottom: 20px;
    &.el-menu--collapse {
      margin-left: -10px;
      .el-submenu {
        .el-submenu__title {
          text-align: center;
          span {
            margin-left: 0;
          }
        }
      }
    }

    .el-submenu {
      .el-submenu__title {
        span {
          margin-left: 10px;
        }
        color: #000;
        // height: 40px;
        border-radius: 4px;
        // line-height: 40px;
      }
      &.is-active {
        .el-submenu__title {
          background: $bg-color-grey !important;
          .iconfont {
            &.active {
              display: initial;
            }
            &.default {
              display: none;
            }
          }
        }
      }
      .iconfont {
        width: 29px;
        height: 29px;
        font-size: 18px;
        color:$text-color;
        &.active {
          display: none;
          background: linear-gradient(
            136deg,
            #353ff1 0%,
            #374cf2 33%,
            #3f7bf8 100%
          );
          color: transparent;
          background-clip: text;
          &.user {
            background: linear-gradient(139deg, #802aff 0%, #dd2afd 100%);
            color: transparent;
            background-clip: text;
          }
          &.material{
            background: linear-gradient(135deg, #1CAC50 0%, #17B95C 31%, #2DEA90 100%);
            color: transparent;
            background-clip: text;
          }
          &.set {
            background: linear-gradient(144deg, #0091ff 0%, #00d5ff 100%);
            color: transparent;
            background-clip: text;
          }
          
        }
        &.default {
          display: initial;
        }
      }

      .el-submenu__icon-arrow {
        font-size: 14px;
      }
    }
    .el-menu-item {
      // height: 40px !important;
      // line-height: 40px !important;
      border-radius: 4px !important;
      min-width: 0 !important;
      &:hover {
        // background: none!important;
        color: $text-color-w !important;
        .iconfont {
          color: $text-color-w !important;
        }
      }
      &.is-active:hover {
        color: $text-color-main !important;
        .iconfont {
          color: $text-color-main !important;
        }
      }
    }
  }
}
</style>
