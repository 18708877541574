 <template>
    <el-dialog
        title="修改密码"
        :visible.sync="isShow"
        width="450px"
        :before-close="handleClose">
        <el-form label-width="100px">
            <el-form-item  label="当前密码">
                <el-input type="password" show-password v-model="form.old_password" placeholder="请输入当前密码"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
                <el-input type="password" show-password v-model="form.new_password" placeholder="请输入新密码"></el-input>
            </el-form-item>
            <el-form-item label="确认新密码">
                <el-input type="password" show-password v-model="form.check_new_password" placeholder="请输入确认新密码"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose()" size="small">取 消</el-button>
            <el-button type="primary" @click="submit()" size="small">确 定</el-button>
        </span>
    </el-dialog>
  </template>
  <script>
  import verify from '@/utils/verify.js';
  export default {
    name:'updatePwdDialog',
    model:{
        prop:'isShow',
        event:'close'
    },
    props:{
        isShow:{
            type: Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{
                old_password:'',
                new_password:'',
                check_new_password:''
            }
        }
    },
    methods:{
        handleClose(){
            this.$emit('close',false)
        },
        submit(){

            // 校验表单数据
           if(!this.validFormData()){
            return;
           }

            this.$post('store/store.user/changepassword',this.form).then(res=>{
                if(res.code == 1){
                    this.$message.success('密码修改成功')
                    this.$router.push('/login')
                    this.$emit('close',false)
                }
            })
           
        },
        // 校验表单数据
        validFormData(){
            let {old_password,new_password,check_new_password} = this.form;
            if(!old_password || !new_password || !check_new_password){
                this.$message.error('请补全表单')
                return false;
            }else if(!verify.isPwd(new_password)){
                this.$message.error('请输入正确的密码（8位以上由字母和数字组成的密码）')
                return false;
            }else if(new_password !== check_new_password){
                this.$message.error('两次密码输入不一致')
                return false;
            }
            return true;
        }
    }
  }
  </script>