<template>
    <el-image  :src="realSrc" :fit="fit" :lazy="isLazy"></el-image>
</template>
<script>
import {getRealSizeImgUrl} from '@/utils/util.js'
export default {
    name:'lazyImage',
    props:{
        src:{
            type:String,
            default:''
        },
        fit:{
            type:String,
            default:'cover'
        },
        width:{
            type:Number,
        },
        height:{
            type:Number,
        },
        isLazy:{
            type:Boolean,
            default:()=>{
                return true
            }
        },
    },
    computed:{
        realSrc(){
            let realSrc = getRealSizeImgUrl(this.src,this.width,this.height);
            return realSrc;
        }
    },
    methods:{
    }
}
</script>
<style lang="scss" scoped>
    .el-image{
        width: 100%;
        height: 100%;;
    }
</style>