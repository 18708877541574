/**
 * 工具类
 */
module.exports = {
    escapeHtml(str) {
        var arrEntities={'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'};
        return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];});
    },
    /**
     * 格式化日期格式 (用于兼容ios Date对象)
     */
    format_date(time) {
        // 将xxxx-xx-xx的时间格式，转换为 xxxx/xx/xx的格式
        return time.replace(/\-/g, '/');
    },

    /**
     * 对象转URL
     */
    urlEncode(data) {
        var _result = [];

        for (var key in data) {
            var value = data[key];

            if (value.constructor == Array) {
                value.forEach((_value) => {
                    _result.push(key + '=' + _value);
                });
            } else {
                _result.push(key + '=' + value);
            }
        }

        return _result.join('&');
    },

    /**
     * 遍历对象
     */
    objForEach(obj, callback) {
        Object.keys(obj).forEach((key) => {
            callback(obj[key], key);
        });
    },

	
	query() {
		var query = {};
		// #ifdef H5
		var search = window.location.search.substring(1);
		var vars = search.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			query[pair[0]] = pair[1];
		}
		return query;
		// #endif
		return query;
	},

     // oss获取实际显示尺寸的图片
     getRealSizeImgUrl(url, width, height){
        let realUrl = url;
        width = width?Math.floor(width):0;
        height = height?Math.floor(height):0;
        //不符合条件的个别图片
        let filterImgAry = ['https://img.eartcn.cn/20220921150149ef5556833.png']
        if(!width || !url ||url.indexOf('?')>-1 || filterImgAry.indexOf(url)>-1 ){
            return realUrl;
        }

        // 符合条件的oss压缩尺寸处理
        // oss压缩
        if(url.indexOf('.oss-')>-1 || url.indexOf('img.eartcn.cn')>-1 || url.indexOf('img.pai.art')>-1){
            realUrl = `${url}?x-oss-process=image/resize,m_fill,w_${width}`;
            if(height){
                realUrl = `${realUrl},h_${height}`
            }
        }

        // 七牛云压缩
        if(url.indexOf('imgcdn.ecbao.cn')>-1){
            realUrl = `${url}?imageMogr2/thumbnail/${width}`;
            if(height){
                realUrl = `${realUrl}x${height}`
            }
        }

        return realUrl;
    },
};
