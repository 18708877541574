<template>
  <div class="app-header">
    <div class="page-header">
      <div class="header-left">
        <a href="/" class="logo-wrapper">
          <img :src="webConfig.login_logo" alt="logo" />
        </a>
        <el-dropdown @command="handleCommand">
          <div class="account-dropdown-link flex-y-center">
            <div class="avatar-img-wrapper">
              <i class="avatar-img iconfont icon-avatar"></i>
            </div>
            {{ userInfo.user_name }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-lock" command="updatePwd"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item icon="el-icon-switch-button" command="logout"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="header-right">
        <div class="amount">
          剩余额度：{{ balance || "--" }}
          <template v-if="pai_link_is_open == 1">
            <el-button
              type="text"
              @click="getBalance()"
              title="刷新"
              icon="el-icon-refresh"
            ></el-button>
            <el-button type="text" @click="openRechargeDialog()"
              >充值</el-button
            >
          </template>
          <el-button v-else type="text" @click="goOpenPaiLinkPage()"
            >开通</el-button
          >
        </div>
      </div>
    </div>
    <div class="page-header-block"></div>
    <update-pwd-dialog v-model="showUpdatePwdDialog"></update-pwd-dialog>
    <recharge-dialog
      v-model="showRechargeDialog"
      :balance="balance"
      @refreshBalance="getBalance()"
    ></recharge-dialog>
  </div>
</template>
<script>
import updatePwdDialog from "@/components/updatePwdDialog.vue";
import rechargeDialog from "@/components/rechargeDialog.vue";
import siteinfo from "@/utils/siteinfo";
export default {
  name: "appHeader",
  components: {
    updatePwdDialog,
    rechargeDialog,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return null;
      },
    },
    webConfig: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      showUpdatePwdDialog: false,
      showRechargeDialog: false,
      balance: "",
      pai_link_is_open: 1, //是否开通派链
    };
  },
  mounted() {
    this.getBalance();
  },
  methods: {
    // 去开通派链
    goOpenPaiLinkPage() {
      window.open(siteinfo.siteroot + "index.php?s=/store/setting/bceconfig");
    },
    // 获取余额
    getBalance() {
      this.balance = "";
      this.$get("store/index/get_balance", {}).then((res) => {
        if (res.code == 1) {
          let { pai_link_is_open, balance } = res.data;
          balance = balance ? this.formatNumber(balance) : "--";
          this.balance = balance;
          this.pai_link_is_open = pai_link_is_open;
        }
      });
    },
    openRechargeDialog() {
      this.getBalance();
      this.showRechargeDialog = true;
    },
    handleCommand(command) {
      if (command == "logout") {
        this.logout();
      } else if (command == "updatePwd") {
        this.updatePwd();
      }
    },
    logout() {
      this.$router.push("/login");
    },
    // 修改密码
    updatePwd() {
      this.showUpdatePwdDialog = true;
    },
  },
};
</script>
<style lang="scss">
.page-header-block {
  height: 75px;
}
.page-header {
  height: 75px;
  background: #232630;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-box-pack: justify;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 9;
  border-bottom: 1px solid $border-color-dark;
  .header-left,
  .header-right {
    display: flex;
    align-items: center;
    .amount {
      font-size: 16px;
      color: #fff;
      .el-button {
        margin-left: 10px;
        font-size: 16px;
      }
    }
    .divider {
      height: 24px;
      width: 1px;
      background: $border-color;
      margin: 0 20px;
    }
  }
  .logo-wrapper {
    z-index: 1;
    position: relative;
    height: 34px;
    width: 210px;

    img {
      height: 100%;
    }
  }
  .account-dropdown-link {
    position: relative;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    .avatar-img-wrapper {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      background: $bg-color;
      border-radius: 8px;
      margin-right: 10px;
      .avatar-img {
        background: linear-gradient(144deg, #0091ff 0%, #00d5ff 100%);
        color: transparent;
        background-clip: text;
        font-size: 32px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translate(0, -50%);
      height: 35px;
      width: 1px;
      background: #333;
    }
  }

  .header-menu {
    display: flex;
    flex: 1;
    align-items: center;
    .menu-item {
      position: relative;
      margin-right: 40px;
      word-break: keep-all;
      display: flex;
      a,
      .router-link {
        color: rgba(0, 0, 0, 0.5);
        &.router-link-exact-active {
          color: rgba(0, 0, 0, 1);
        }
      }
      .icon-new {
        position: absolute;
        top: -16px;
        right: -20px;
        width: 26px;
      }
    }
  }
  .search-view {
    position: relative;
  }
  .search-wrapper {
    background-color: #fff;
    position: absolute;
    transition: all 0.26s ease-out;
    z-index: 99;
    right: -550px;
    transform: translateX(0) translateY(-50%);
    top: 50%;
    display: flex;
    align-items: center;
    width: 600px;
    height: 80px;
    z-index: 0;
    background: #fff;
    &.open {
      right: 0;
    }
    input {
      flex-grow: 1;
      flex-shrink: 1;
      display: block;
      border: none;
      font-size: 14px;
      &:focus {
        border: none;
        outline: none;
      }
    }
    .iconfont {
      cursor: pointer;
      flex-shrink: 0;
      font-size: 24px;
      color: #999;
      &.icon-sousuo {
        margin: 0 24px;
      }
      &.icon-guanbi {
        margin-left: 24px;
      }
    }
  }
  .search-result-box {
    position: absolute;
    top: 80px;
    left: 73px;
    width: 480px;
    transform-origin: center top;
    z-index: 9;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin: 5px 0;
    .result-class {
      padding: 10px 0;
      & + .result-class {
        border-top: 1px solid $border-color;
      }
    }
    .class-title {
      padding-left: 20px;
      font-size: 12px;
      color: $text-color-grey;
      line-height: 30px;
    }
    .result-list {
      max-height: 200px;
      overflow: auto;
      .empty {
        color: $text-color-grey;
        line-height: 60px;
        text-align: center;
        font-size: 12px;
      }
    }
    .result-item {
      padding: 0 20px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 34px;
      line-height: 34px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background: #f5f7fa;
      }
      &.active {
        color: $text-color-main;
        background: #f5f7fa;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: -6px;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      border-width: 6px;
      border-top-width: 0;
      border-color: rgba(0, 0, 0, 0);
      border-bottom-color: #ebeef5;
      border-style: solid;
    }
  }
  .login-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    padding-right: 20px;
    height: 100%;
    background: #fff;

    .login-btn {
      width: 89px;
      height: 35px;
      border: 2px solid $border-color;
      border-radius: 30px;
      color: #000;
      cursor: pointer;
    }
  }
}
</style>
