import Vue from 'vue'
import App from './App.vue'
import router from './router'
import infiniteScroll from "vue-infinite-scroll";
import lazyImage from "./components/lazyImage";
import {post,get} from './utils/http.js';
import SlideVerify from 'vue-monoplasty-slide-verify';// 验证码组件
import Mixin from './utils/mixins';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
Vue.use(SlideVerify);
Vue.mixin(Mixin);
Vue.use(infiniteScroll);
Vue.component(lazyImage.name,lazyImage);
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.config.productionTip = false

import VueQuillEditor from 'vue-quill-editor';
import * as Quill from 'quill'; //引入编辑器
import resizeImage from 'quill-image-resize-module'; // 图片缩放组件。
Quill.register('modules/resizeImage ', resizeImage);
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueQuillEditor);



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
