<template>
  <el-dialog
    title="派链充值"
    :visible.sync="isShow"
    width="920px"
    :before-close="handleClose"
  >
    <div>
      <div class="recharge-dialog-container">
        <div class="recharge-container-title">额度数据</div>
        <div class="recharge-data ml__20">
          <p>
            剩余额度：<span class="left-num">{{ balance }}</span> 条
          </p>
          <div class="record-button" @click="openRechargeRecord">充值记录</div>
        </div>
        <div class="recharge-container-title">套餐选择</div>
        <div class="recharge-type-list">
          <div
            class="recharge-type-item"
            :class="index === curPackageIndex ? 'active' : ''"
            :key="item.id"
            v-for="(item, index) in rechargePackageList"
            @click="changePackage(index)"
          >
            <div class="item-tag">首次优惠</div>
            <p class="push-count">
              {{ item.package_name }}{{ item.package_count }}
            </p>
            <div class="price-info">
              <span class="unit">￥</span>
              <span class="price">{{ item.package_price }}元</span>
              <span class="line-price">{{ item.package_line_price }}</span>
            </div>
            <p class="single-price">{{ item.average_price }}元/条</p>
          </div>
        </div>
        <div class="recharge-container-title">支付方式</div>
        <div class="recharge-pay-container" v-if="rechargePackageList.length">
          <div class="tab-menus">
            <div
              class="tab-menu-item"
              :class="{ active: payType == 2 }"
              @click="chagePayType(2)"
            >
              微信支付
            </div>
            <div
              class="tab-menu-item"
              :class="{ active: payType == 1 }"
              @click="chagePayType(1)"
            >
              支付宝支付
            </div>
          </div>
          <div class="tab-con">
            <div class="ewm-box">
              <div class="pay-ewm-wrapper">
                <img class="pay-ewm" :src="payEwm" />
                <div class="pay-success-tips" v-show="payStatus == 1">
                  <i class="iconfont icongouxuan"></i>
                  <span>支付成功</span>
                </div>
              </div>
              <div>
                <div class="pay-price">
                  <span class="price-sign">¥</span
                  >{{ rechargePackageList[curPackageIndex].package_price }}
                </div>
                <div class="pay-tips" v-if="payType == 2">
                  <i class="iconfont icon-wx-pay"></i>使用微信扫一扫，轻松支付！
                </div>
                <div class="pay-tips" v-else>
                  <i class="iconfont iconzhifubao1"></i
                  >使用支付宝扫一扫，轻松支付！
                </div>
              </div>
            </div>
            <div class="other-btns">
              <div class="other-btn-item">购买协议</div>
              <div class="other-btn-item">自动续费服务协议</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  <script>
import siteinfo from "@/utils/siteinfo";
export default {
  name: "rechargeDialog",
  model: {
    prop: "isShow",
    event: "close",
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    balance: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      curPackageIndex: 0,
      rechargePackageList: [], //套餐列表
      payEwm: "", //支付二维码
      payOrderNo: "",
      payType: 2, //支付方式 1：支付宝  2：微信
      payStatus: 0, //支付状态  0：未支付  1：已支付
      timeout: null,
    };
  },
  watch: {
    isShow(val) {
      if (val) {
        // 没有数据需要初始化
        if (this.rechargePackageList.length == 0) {
          this.initData();
        }
      } else {
        // 关闭弹窗清除定时器
        this.clearTimeOut();
        this.clearData();
      }
    },
  },
  methods: {
    initData() {
      // 获取数据
      this.getRechargeTypeList();
    },
    handleClose() {
      this.$emit("close", false);
    },
    // 清除数据
    clearData() {
      this.payEwm = "";
      this.payOrderNo = "";
      this.payStatus = 0;
    },

    //切换套餐
    changePackage(index) {
      if (this.curPackageIndex == index) {
        return;
      }
      this.curPackageIndex = index;
      this.recharge();
    },
    // 获取套餐列表
    getRechargeTypeList() {
      this.$get("store/index/package_list", {}).then((res) => {
        if (res.result == 1) {
          this.rechargePackageList = res.data;
          this.curPackageIndex = 0;
          this.recharge();
        }
      });
    },
    // 支付方式切换
    chagePayType(type) {
      if (this.payType == type) {
        return;
      }
      this.payType = type;
      this.recharge();
    },
    // 充值
    recharge() {
      this.payStatus = 0;
      this.payEwm = "";
      this.payOrderNo = "";
      this.$post("store/index/package_buy", {
        package_id: this.rechargePackageList[this.curPackageIndex].id,
        pay_type: this.payType,
      }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.payEwm = data.qr_code;
          this.payOrderNo = data.order_no;
          this.checkPay();
        }
      });
    },
    //检查支付状态
    checkPay() {
      if (!this.payOrderNo) {
        return;
      }
      this.$post("store/index/check_pay", {
        order_no: this.payOrderNo,
      })
        .then((res) => {
          this.$emit("refreshBalance");
        })
        .catch((res) => {
          if (res.result == 0) {
            this.clearTimeOut();
            this.timeout = setTimeout(() => {
              this.checkPay();
            }, 5000);
          }
        });
    },
    clearTimeOut() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    },
    // 充值记录
    openRechargeRecord() {
      window.open(
        siteinfo.siteroot + "index.php?s=/store/gas/recharge_record_list"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.recharge-dialog-container {
  overflow-y: auto;
  padding: 0 40px;
}
.recharge-data {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $text-color;
}
.recharge-data .left-num {
  font-size: 18px;
  color: #f51212;
  font-weight: bold;
}
.recharge-data .record-button {
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 12px;
  color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  border: 1px solid #409eff;
}
.recharge-container-title {
  font-size: 16px;
  line-height: 20px;
  color: $text-color;
  display: flex;
  align-items: center;
  margin: 25px 0 18px;
}
.recharge-container-title:before {
  content: "";
  width: 4px;
  height: 20px;
  display: block;
  margin-right: 12px;
  background-color: #194e86;
}
.recharge-type-list {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
}
.recharge-type-list .recharge-type-item {
  width: 180px;
  height: 152px;
  border-radius: 10px;
  border: 1px solid #d3e6fa;
  box-shadow: 0px 0px 3px 0px #d3e6fa;
  position: relative;
  cursor: pointer;
}
.recharge-type-list .recharge-type-item .item-tag {
  width: 72px;
  height: 26px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 26px;
  border-radius: 13px 0px 13px 0px;
  background-color: rgb(255, 109, 50);
  position: absolute;
  left: -1px;
  top: -13px;
  display: none;
}
.recharge-type-list .recharge-type-item .push-count {
  margin-top: 25px;
  font-size: 16px;
  color: #6fa7e1;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
}
.recharge-type-list .recharge-type-item .price-info {
  height: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 28px;
}
.recharge-type-list .recharge-type-item .price-info .unit {
  font-size: 14px;
  color: #f56c6c;
}
.recharge-type-list .recharge-type-item .price-info .price {
  font-size: 26px;
  color: #f56c6c;
  margin: 0px 5px;
  line-height: 30px;
}
.recharge-type-list .recharge-type-item .price-info .line-price {
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
}
.recharge-type-list .recharge-type-item .single-price {
  margin-top: 24px;
  color: #356699;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}
.recharge-type-list .recharge-type-item.active {
  background: #eef6fc;
  border: 1px solid #409eff;
}
.recharge-type-list .recharge-type-item.active .item-tag {
  display: block;
}
.recharge-type-list .recharge-type-item.active .push-count {
  color: #115aa6;
}
.recharge-pay-container {
  display: flex;
  height: 248px;
  background: $bg-color-grey;
  //   border: 1px solid #f6f6f6;
  border-radius: 5px 0px 0px 5px;
  overflow: hidden;
}
.recharge-pay-container .tab-menus {
  flex-shrink: 0;
  padding: 15px 0 15px 10px;
  width: 120rpx;
  height: 248px;
  background: $bg-color-grey;
  box-sizing: border-box;
}
.recharge-pay-container .tab-menus .tab-menu-item {
  margin-bottom: 10px;
  text-align: center;
  width: 110px;
  height: 35px;
  line-height: 35px;
  border-radius: 18px 0px 0px 18px;
  font-size: 14px;
  color: $text-color;
  cursor: pointer;
}
.recharge-pay-container .tab-menus .tab-menu-item.active {
  background: linear-gradient(82deg, #97bbff, #034ad3);
  color: #fff;
}
.recharge-pay-container .tab-con {
  flex: 1;
  padding: 30px 55px;
}
.recharge-pay-container .ewm-box {
  display: flex;
  align-items: center;
}
.recharge-pay-container .ewm-box .pay-ewm-wrapper {
  position: relative;
  flex-shrink: 0;
  margin-right: 25px;
  width: 140px;
  height: 140px;
  background: #f6f6f6;
  border-radius: 5px;
  overflow: hidden;
}
.recharge-pay-container .ewm-box .pay-ewm-wrapper img {
  width: 140px;
  height: 140px;
}
.recharge-pay-container .pay-success-tips {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.77);
}
.recharge-pay-container .pay-success-tips .iconfont {
  font-size: 26px;
  margin-bottom: 15px;
}
.recharge-pay-container .ewm-box .pay-price {
  margin-bottom: 16px;
  font-size: 48px;
  color: #ff5f00;
  font-weight: bold;
}
.recharge-pay-container .ewm-box .pay-price .price-sign {
  margin-right: 3px;
  font-size: 25px;
}
.recharge-pay-container .ewm-box .pay-tips {
  font-size: 14px;
  color: $text-color;
}
.recharge-pay-container .ewm-box .pay-tips .iconfont {
  margin-right: 10px;
  font-size: 20px;
  color: #09bb07;
  vertical-align: middle;
}
.recharge-pay-container .ewm-box .pay-tips .iconfont.iconzhifubao1 {
  color: #1f65ec;
}
.recharge-pay-container .other-btns {
  margin-top: 25px;
  display: flex;
}
.recharge-pay-container .other-btns .other-btn-item {
  margin-right: 50px;
  cursor: pointer;
  width: 133px;
  height: 31px;
  line-height: 31px;
  background: #f3f3f3;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.ml__20 {
  margin-left: 20px;
}
.mb__20 {
  margin-bottom: 20px;
}
.normal-tips {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  padding: 5px 0;
}
</style>