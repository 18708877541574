<template>
  <div id="app">
    <app-header v-if="!isLoginPage" ref="appHeader" :user-info="userInfo" :webConfig="webConfig"></app-header>
    <left-menu  v-if="!isLoginPage" v-model="isCollapse"></left-menu>
    <div class="router-view-container" :class="[{'small-left':isCollapse},{'login':isLoginPage}]">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <!-- <app-footer v-show="$route.path != '/market'"></app-footer> -->
  </div>
</template>
<script>
import appHeader from "@/components/appHeader";
import leftMenu from "@/components/leftMenu";
import appFooter from "@/components/appFooter";
import logoUrl from '@/assets/image/logo.png'
export default {
  name: "App",
  components: {
    appHeader,
    leftMenu,
    appFooter,
  },
  data() {
    return {
      isLoginPage:false,
      isCollapse:false,
      userInfo: {},
      webConfig:{}
    };
  },
  watch:{
    '$route.path':{
      handler(val){
        if(val.indexOf('login')>-1 || val.indexOf('register')>-1 ){
          this.isLoginPage = true;
        }else{
          this.isLoginPage = false;
        }
      },
      immediate:true
    }
  },
  mounted() {
    this.getLayoutSetting();
  },
  methods: {
    getLayoutSetting(){
      this.$get('store/metaverse.func/getLayoutSetting').then(res=>{
        if(res.code == 1){
          let data = res.data;
          this.userInfo = data.store.user;
          this.webConfig = data.web_config;
          let {website_name,website_icon} =  this.webConfig
          this.webConfig.login_logo = logoUrl;
          // document.title = website_name;
          // document.querySelector('link[rel="icon"]').setAttribute('href', website_icon)
        }
      })
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/style/common.scss";
@import "@/assets/style/elReset.scss";
#app{
  min-width: 1280px;
}
.router-view-container {
  height: calc(100vh - 75px);
  position: relative;
  margin-left: 220px;
  // padding: 0 28px 0;
  transition: all 0.2s ease-out;
  &.small-left {
    margin-left: 65px;
    .main-header {
      left: 65px;
    }
  }
  &.login{
    height: calc(100vh)!important;
    margin-left: 0!important;
  }
}
</style>
