<template>
     <div class="footer">
        <div class="container">
          <div class="footer-bottom">
            <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">ICP备案号：湘ICP备2022010980号</a>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001792" >
              <span class="gn-icon"></span></i>湘公网安备 43019002001792号</a>
            <p>增值电信业务经营许可证：湘B2-20220409</p>
            <p class="copyright">©长沙爱聚科技有限公司（海南数艺科技有限公司系爱聚全资子公司）</p>
          </div>
        </div>
      </div>
</template>
<script>
export default {
  name: "appFooter",
};
</script>
<style lang="scss" scoped>
.footer {
  font-size: 12px;
  .container {
    margin: 0 auto;
    width: 1000px;
    .footer-bottom {
      p,
      a {
        display: flex;
        align-items: center;
        color: #666;
        line-height: 2.5;
      }
      a {
        color: #999;
      }
      .gn-icon {
        margin-right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        background: url("@/assets/image/icon-ga.png") center no-repeat;
      }
    }
  }
}
</style>