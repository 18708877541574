import Vue from 'vue'
import VueRouter from 'vue-router'
const home = () => import('@/views/index.vue')
const space = () => import('@/views/space/space.vue')
const login = () => import('@/views/login/login.vue')
const material = () => import('@/views/material.vue')
const userList = () => import('@/views/user/list.vue')
const feedback = () => import('@/views/user/feedback.vue')
const pageSet = () => import('@/views/set/page.vue')
const mobile = ()=> import('@/views/set/mobile.vue')
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: '元宇宙空间',
        meta: { visiable: true, icon: 'icon-location',activeIcon:'icon-location-fill' },
        redirect: '/space/main',
        component: home,
        children: [
            {
                path: '/space/main',
                name: '主空间',
                meta: { visiable: true, icon: 'icon-shangcheng' },
                component: space,
            },
            // {
            //     path: '/space/third',
            //     name: '第三空间',
            //     meta: { visiable: true, icon: 'icon-shangcheng' },
            //     component: space,
            // }
        ]
    }, 
    {
        path: '/material',
        name: '元宇宙素材库',
        meta: { visiable: true, icon: 'icon-img',activeIcon:'icon-img-fill' },
        redirect: '/material/list',
        component: home,
        children: [
            {
                path: '/material/list',
                name: '公共素材',
                meta: { visiable: true, icon: 'icon-shangcheng' },
                component: material,
            }
        ]
    }, 
    {
        path: '/user',
        name: '用户',
        meta: { visiable: true, icon: 'icon-user-2',activeIcon:'icon-user-2-fill' },
        redirect: '/user/list',
        component: home,
        children: [
            {
                path: '/user/list',
                name: '用户列表',
                meta: { visiable: true, icon: 'icon-shangcheng' },
                component: userList,
            },
            {
                path: '/user/feedback',
                name: '意见反馈',
                meta: { visiable: true, icon: 'icon-shangcheng' },
                component: feedback,
            }
        ]
    },
    {
        path: '/set',
        name: '设置',
        meta: { visiable: true, icon: 'icon-setting',activeIcon:'icon-setting-fill' },
        redirect: '/set/page',
        component: home,
        children: [
            {
                path: '/set/page',
                name: '页面设计',
                meta: { visiable: true, icon: 'icon-shangcheng' },
                component: pageSet,
            },
            {
                path: '/set/app',
                name: '移动端设置',
                meta: { visiable: true, icon: 'icon-shangcheng' },
                component: mobile,
            }
        ]
    },
    {
        path: '/login',
        meta: { visiable: false },
        component: login
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
})

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
})

router.beforeEach((to, from, next) => {
    if (to.path.startsWith('/login')) {
        window.localStorage.removeItem('token')
        next()
    } else {
        let token = window.localStorage.getItem('token')
        if (!token) {
           sessionStorage.setItem('beforeLoginPage',to.fullPath)
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})

export default router;



//解决重复点击菜单引起路由重复报错
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}